<template>
  <div class="setting">
    <!-- <van-nav-bar title="密码设置" left-arrow @click-left="onClickLeft" /> -->

    <van-form @submit="onSubmit">
      <van-cell-group inset>
        <van-cell title="" label="密码必须是6-16位字符，可以使用数字、大小写字母和特殊字符； 特殊字符需在“~ @ # $ % * _ - + = : , . ?”范围内选择。" />
        <van-field
          v-model="password"
          name="password"
          label="新密码"
          :type="!isShow ? 'password' : 'text'"
          placeholder="请输入密码"
          required
          maxlength="16"
          :right-icon="isShow ? 'eye' : 'eye-o'"
          @click-right-icon="isShow = !isShow"
          :rules="[{ required: true, message: '请输入密码' }, { validator: checkpwd , message: '请按要求输入密码' }]"
        />

        <van-field
          v-model="againPassword"
          name="againPassword"
          label="重复密码"
          type="password"
          placeholder="请输入密码"
          required
          maxlength="16"
          :rules="[{ required: true, message: '请再次输入密码' }, { validator: checkpwdAgain, message: '和新密码不一致' }]"
        />
      </van-cell-group>
      <div style="margin: 16px;">
        <van-button round block type="primary" native-type="submit">
          提交
        </van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import { ref } from 'vue';
import { showToast  } from 'vant'
import { settingPassword } from '@/service/user'
export default {
  setup() {
    // const username = ref('');
    const storage= localStorage.getItem("token-hyxt");
    const token=JSON.parse(storage);
    const isShow = ref(false);
    const password = ref('');
    const againPassword = ref('');
    const reg = /^[\da-zA-Z~@#$%*+-=:,._\\?\\[\]{}]{6,16}$/;
    // 新密码校验
    const checkpwd = (val) => {
      return reg.test(val);
    }
    // 重复密码校验
    const checkpwdAgain = (val) => {
      return val === password.value;
    }
    const onSubmit = async (values) => {
      // console.log('submit', values);
      const res = await settingPassword({userId:token.userId,password:values.password});
      console.log(res);
      showToast ('修改成功');
      history.back();
    };
    const onClickLeft = () => history.back();
    return {
      isShow,
      password,
      againPassword,
      checkpwd,
      checkpwdAgain,
      onSubmit,
      onClickLeft,
    };
  }
}
</script>

<style lang="scss">
  .van-form {
    margin-top: 30px;
  }
</style>
